
import api from "@/base/utils/request";

// 获取分类列表
export const serviceCategory = data => {
  return api({
    url: "/admin/consult/serviceCategory/index",
    method: "post",
    data
  });
};
export const deleteCategory = data => {
  return api({
    url: "/admin/consult/serviceCategory/del",
    method: "post",
    data
  });
}
export const addCategory = data => {
  return api({
    url: "/admin/consult/serviceCategory/save",
    method: "post",
    data
  });
}