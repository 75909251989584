<template>
  <div>
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :thead="thead"
      :on-fetch="getList"
      :on-delete="deleteItem"
      :on-toggle="toggleItem"
      :on-update="updateItem"
      @command="handleOperation"
    >
      <template #tabletop>
        <el-button type="primary" size="small" @click="showAddCategory = true"
          >新增分类</el-button
        >
        <!-- <el-button type="danger" size="small" @click="onDelete">删除</el-button> -->
      </template>
      <AddCategory
        :showAddCategory="showAddCategory"
        :editObj="editObj"
        @closeAddDialog="closeAddDialog"
        @updateData="updateData"
      />
    </list-layout>
  </div>
</template>

<script>
import ListLayout from '@/base/layout/ListLayout'
import AddCategory from '@/modules/consult/components/ConsultService/AddCategory'
import {
  serviceCategory,
  deleteCategory,
} from '@/modules/consult/api/consult-service/category-list'

export default {
  components: {
    ListLayout,
    AddCategory,
  },
  data() {
    return {
      filterForm: {},
      thead: [
        // {
        //   label: '分类ID',
        //   prop: 'id',
        // },
        {
          label: '分类名称',
          prop: 'name',
        },
        {
          label: '询问用户数',
          prop: 'users_count',
        },
        {
          label: '已解决',
          prop: 'resolved_count',
        },
        {
          label: '未解决',
          prop: 'unresolved_count',
        },
        {
          label: '创建时间',
          prop: 'create_time',
        },

        {
          label: '操作',
          type: 'operation',
          width: 150,
          operations: [
            { command: 'edit', text: '编辑' },
            { command: 'delete' },
          ],
        },
      ],
      showAddCategory: false,
      editObj: {},
    }
  },
  methods: {
    getList() {
      return serviceCategory()
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList()
    },
    updateItem(data) {
      return updateCategorySort(data)
    },
    toggleItem(data) {
      return updateCategoryShow(data)
    },
    deleteItem(data) {
      return deleteCategory({ id: data[0]})
    },
    updateData() {
      this.showAddCategory = false
      this.refreshList()
    },
    closeAddDialog() {
      this.showAddCategory = false
      this.editObj = {}
    },
    onDelete() {
      this.$refs.listLayout.deleteSelected()
    },
    handleOperation(e) {
      switch (e.command) {
        case 'edit': {
          this.editObj = e.row
          this.showAddCategory = true
          break
        }
        default:
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
